import { lazy, Suspense } from "react";
import Splashscreen from "./pages/Splashscreen";
import Api from "./api";
import { update } from "./store/User";
import { store } from "./store";
import { upadteFilter } from "./store/filters";

const Routes = lazy(async () => {
  try {
    if (store.getState().reducer?.tk) {
      const res = await Api.get("/common/data");
      store.dispatch(update(res?.data));
      const data = store.getState();
      store.dispatch(
        upadteFilter(
          res?.data?.role === "super admin"
            ? data?.filters?.employee?._id
              ? {}
              : { employee: { _id: "", name: "All" } }
            : { employee: { _id: res?.data?._id, name: res?.data?.name } }
        )
      );
    }
  } catch (error) {
    console.log(error);
  }

  return import("./Routes");
});

const App = () => {
  return (
    <Suspense fallback={<Splashscreen />}>
      <Routes />
    </Suspense>
  );
};

export default App;
