import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const slice = createSlice({
  name: "filter",
  initialState: { year: moment().year(), month: moment.months()[moment().month()], employee: { _id: "", name: "All" } },
  reducers: {
    upadteFilter: (state, action) => ({ ...state, ...action.payload })
  }
});

export const { upadteFilter } = slice.actions;
export default slice.reducer;
